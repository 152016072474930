<div class="card">
  <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  <p-table
    #dt
    [value]="items"
    [lazy]="true"
    (onLazyLoad)="loadTableItems.emit($event)"
    responsiveLayout="scroll"
    dataKey="id"
    [selection]="selectedItems"
    (selectionChange)="selectionChange.emit($event)"
    [selectAll]="selectAll"
    (selectAllChange)="selectAllChange.emit($event)"
    [rows]="rowsPerPage"
    [totalRecords]="totalItems"
    [loading]="loading"
    [globalFilterFields]="globalFilterFields"
    [currentPageReportTemplate]="currentPageReportTemplate"
    [sortField]="sortField"
    [showCurrentPageReport]="true"
    styleClass="p-datatable-striped p-datatable-gridlines"
  >
    <ng-template pTemplate="caption">
      <div *ngIf="title" class="flex align-items-center justify-content-between">
        <h1 class="m-0">{{ title }}</h1>
      </div>
      <ng-container *ngTemplateOutlet="caption"></ng-container>
    </ng-template>
    <ng-template pTemplate="header">
      <tr role="row">
        <th *ngIf="hasCheckbox" style="width: 3rem" id="header-checkbox" role="columnheader">
          <p-tableHeaderCheckbox ariaLabel="Restore"></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngIf="sortableHeaders">
          <th
            *ngFor="let h of sortableHeaders"
            [pSortableColumn]="h.field"
            [id]="h.field + '-column'"
            role="columnheader"
          >
            <div class="d-flex justify-content-between">
              <span>{{ h.label }}</span>
              <p-sortIcon [field]="h.field"></p-sortIcon>
            </div>
          </th>
        </ng-container>
        <ng-container *ngTemplateOutlet="header"></ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr role="row">
        <td *ngIf="hasCheckbox">
          <p-tableCheckbox [value]="item" [ariaLabel]="checkboxLabel!(item)"></p-tableCheckbox>
        </td>
        <ng-container *ngTemplateOutlet="body; context: { $implicit: item }"></ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="d-flex justify-content-between">
        <div class="responsive-container left-container">
          <span class="paginator-text">Results per page:</span>
          <p-dropdown
            [options]="paginationPageSizeOptions"
            [(ngModel)]="rowsPerPage"
            class="custom-paginator-dropdown"
            (onChange)="rowsPerPageChange.emit($event.value)"
          >
            <ng-template pTemplate="dropdownicon">
              <img alt="dropdown icon" src="/assets/img/dropdown_arrow.svg" />
            </ng-template>
          </p-dropdown>
        </div>
        <div class="responsive-container">
          <span class="paginator-text">{{ showing }}:</span>
          <p-paginator
            [rows]="rowsPerPage"
            [totalRecords]="totalItems"
            [first]="first"
            (onPageChange)="changePage($event)"
          >
            <ng-template pTemplate="firstpagelinkicon">
              <img alt="first page icon" src="/assets/img/first_arrow.svg" />
            </ng-template>
            <ng-template pTemplate="previouspagelinkicon">
              <img alt="previous page icon" src="/assets/img/prev_arrow.svg" />
            </ng-template>
            <ng-template pTemplate="nextpagelinkicon">
              <img alt="next page icon" src="/assets/img/next_arrow.svg" />
            </ng-template>
            <ng-template pTemplate="lastpagelinkicon">
              <img alt="last page icon" src="/assets/img/last_arrow.svg" />
            </ng-template>
          </p-paginator>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="sorticon" let-sortOrder>
      <app-table-sort-icon [sortOrder]="sortOrder"></app-table-sort-icon>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <div class="responsive-container">
        <span>Results per table:</span>
        <p-dropdown
          [options]="paginationPageSizeOptions"
          [(ngModel)]="rowsPerPage"
          (onChange)="rowsPerPageChange.emit()"
        >
          <ng-template pTemplate="dropdownicon">
            <img alt="dropdown icon" src="/assets/img/dropdown_arrow.svg" />
          </ng-template>
        </p-dropdown>
      </div>
    </ng-template>
    <ng-template pTemplate="paginatorfirstpagelinkicon">
      <img alt="first page icon" src="/assets/img/first_arrow.svg" />
    </ng-template>
    <ng-template pTemplate="paginatorpreviouspagelinkicon">
      <img alt="previous page icon" src="/assets/img/prev_arrow.svg" />
    </ng-template>
    <ng-template pTemplate="paginatornextpagelinkicon">
      <img alt="next page icon" src="/assets/img/next_arrow.svg" />
    </ng-template>
    <ng-template pTemplate="paginatorlastpagelinkicon">
      <img alt="last page icon" src="/assets/img/last_arrow.svg" />
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <td colspan="100" class="mx-3 empty-message">{{ emptyMessage }}</td>
    </ng-template>
  </p-table>
</div>
