<ul class="navbar-nav">
  <ng-container *ngIf="headerStyle === headerStyles.DEFAULT">
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" routerLink="/dashboard"><span [ngStyle]="{ 'font-size': fontSize }">Dashboard</span></a>
    </li>
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" routerLink="/reports"><span [ngStyle]="{ 'font-size': fontSize }">Reports</span></a>
    </li>
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" routerLink="/contacts"><span [ngStyle]="{ 'font-size': fontSize }">Contacts</span></a>
    </li>
    <li class="nav-item dropdown" [ngClass]="full ? 'full-width' : ''">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        role="button"
        id="navbarDropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span [ngStyle]="{ 'font-size': fontSize }">Tools</span>
      </a>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
        <a class="dropdown-item" routerLink="/tools/update-cash-on-hand">Update cash on hand</a>
      </ul>
    </li>
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        role="button"
        id="navbarProfileDropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img
          class="header-navbar-icon"
          ngSrc="assets/img/profile_icon.svg"
          alt="Profile"
          [height]="16 * ratio"
          [width]="8 * ratio"
        />
      </a>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarProfileDropdownMenuLink">
        <a class="dropdown-item" routerLink="/committee/">Account</a>
        <a class="dropdown-item" routerLink="/committee/members">Users</a>
        <a class="dropdown-item" routerLink="/login/select-committee/">Switch Committees</a>
        <a class="dropdown-item" (click)="loginService.logOut()">Logout</a>
      </ul>
    </li>
  </ng-container>
  <ng-container *ngIf="headerStyle === headerStyles.LOGIN">
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" (click)="navigateToLoginDotGov()">
        <span [ngStyle]="{ 'font-size': fontSize }">SIGN IN</span>
      </a>
    </li>
  </ng-container>
  <ng-container *ngIf="headerStyle === headerStyles.LOGOUT">
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" (click)="loginService.logOut()">
        <span [ngStyle]="{ 'font-size': fontSize }">Log Out</span>
      </a>
    </li>
  </ng-container>
</ul>
