<div class="grid" *ngIf="committeeID">
  <div class="col-12">
    <h1 class="committee-name">{{ committeeName }}</h1>
    <div class="grid">
      <div class="col-12 committee-info">
        <div
          class="pi pi-circle-on status-circle"
          [ngStyle]="{ color: committeeStatus === 'Active' ? '#4AA564' : '#AEB0B5' }"
        ></div>
        <div class="sub-banner-item">{{ committeeFrequency }}</div>
        |
        <div class="sub-banner-item">{{ committeeTypeLabel }}</div>
        |
        <div class="sub-banner-item">ID: {{ committeeID }}</div>
      </div>
    </div>
  </div>
</div>
